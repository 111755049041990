import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import axiosRetry from 'axios-retry';
import axios from 'axios';
import { ButtonGroup, Button, Spinner, CardTitle, Row, Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { eachDayOfInterval, format, subDays } from 'date-fns';
import { bookieListGenerator } from 'components/dropdownGenerator';
import { syndicateListGenerator } from 'components/dropdownGenerator';

const syndArr = syndicateListGenerator();
const bookieArr = bookieListGenerator('analytics');

axiosRetry(axios, { retries: 10 });

const StakeReport = () => {
  const [loading, setLoading] = useState(false);
  const [displayMessage, setDisplayMessage] = useState('');
  const [syndStakeReport, setSyndStakeReport] = useState([]);

  const [startDate, setStartDate] = useState(new Date(subDays(new Date(), 2)));
  const [endDate, setEndDate] = useState(new Date(subDays(new Date(), 1)));
  
  const [dateArray, setDateArray] = useState([]);
  
  const handleChangeStart = date => {
    setStartDate(date);
    if (endDate) {
      updateDateArray(date, endDate);
    }
  };

  const handleChangeEnd = date => {
    setEndDate(date);
    if (startDate) {
      updateDateArray(startDate, date);
    }
  };

  const updateDateArray = (start, end) => {
    console.log(start)
    console.log(end)
    
    const range = eachDayOfInterval({
      start: start,
      end: end
    }).map(day => format(day, 'yyyy-MM-dd'));
    setDateArray(range);
  };

  const headers = [
    { label: 'Partner', key: 'name' },
    { label: 'Source', key: 'bookie' },
    { label: 'Number Of Bets', key: 'betCount' },
    { label: 'Stake', key: 'stake' },
    { label: 'Winnings', key: 'winnings' },
    { label: 'Profit', key: 'profit' },
  ];

  const loadingSpinner = loading ? (
    <>
      {' '}
      <Spinner size="sm"></Spinner>
    </>
  ) : null;

  const handleGetAllSubmitClick = async () => {
    setLoading(true);

    const responseArr = [];
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();

    for (let i = 0; i < dateArray.length; i++) {
      try {
        setDisplayMessage(`Retrieving data for ${dateArray[i]}`);
        const response = await axios.get(
          `https://api.valueservices.uk/horsebettingmx/getBets/${dateArray[i]}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        responseArr.push(response.data);
      } catch (e) {
        // basicAlert(`There was an issue with that request: ${e}`);
        console.log(`${e}`);
        return [];
      }
    }

    const placedBets = responseArr
      .flatMap((item) => item)
      .filter((item) => item.status === 'placed');

    const testSyndData = syndArr.flatMap((synd) => {
      const syndData = bookieArr.map((bookie) => {
        return placedBets.filter((bet) => {
          return bet.bookie === bookie && bet.displayName === synd;
        });
      });

      const reducedData = syndData.flatMap((item) => {
        if (item.length > 0) {
          const bookie = item[0].bookie;
          const name = item[0].displayName;
          const betCount = item.length;
          const stake = parseFloat(
            item.reduce((a, b) => a + b.stake, 0).toFixed(2)
          );
          const winnings = parseFloat(
            item.reduce((a, b) => a + b.winnings, 0).toFixed(2)
          );
          const profit = winnings - stake;

          return { bookie, name, betCount, stake, winnings, profit };
        }
        return [];
      });

      return reducedData;

    });

    const totalStake = testSyndData.reduce((a, b) => a + b.stake, 0);
    const totalBets = testSyndData.reduce((a, b) => a + b.betCount, 0);
    const totalWinnings = testSyndData.reduce((a, b) => a + b.winnings, 0);
    const totalProfit = testSyndData.reduce((a, b) => a + b.profit, 0);
    testSyndData.unshift({
      bookie: 'TOTALS',
      name: '',
      stake: totalStake,
      betCount: totalBets,
      winnings: totalWinnings,
      profit: totalProfit,
    });

    setSyndStakeReport(testSyndData);
    setLoading(false);
  };

  const GetAllSubmitButton = (
    <Button color="secondary" onClick={handleGetAllSubmitClick}>
      Submit {loadingSpinner}
    </Button>
  );

  const dateRangePicker = (
    <>
      <div>Start Date:</div>
      <DatePicker
        selected={startDate}
        onChange={handleChangeStart}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showYearDropdown
        scrollableYearDropdown
        dateFormat="dd/MM/yyyy"
      />

      <div>End Date:</div>
      <DatePicker
        selected={endDate}
        onChange={handleChangeEnd}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        showYearDropdown
        scrollableYearDropdown
        dateFormat="dd/MM/yyyy"
      />
    </>
  );

  const reportDownload =
    syndStakeReport.length > 0 ? (
      <Button color="secondary">
        <CSVLink
          filename={`Stakes_${moment(startDate).format('YYYY-MM-DD')}_${moment(
            endDate
          ).format('YYYY-MM-DD')}`}
          data={syndStakeReport}
          headers={headers}
        >
          Download Stake Report
        </CSVLink>
      </Button>
    ) : (
      <Button disabled>Download Stake Report</Button>
    );

  return (
    <>
      {dateRangePicker}
      <Row>
        <Col md="4">
          <hr />
          <CardTitle tag="h3">Request Stake Report </CardTitle>
          <Row>
            <Col>
              <ButtonGroup>
                {GetAllSubmitButton}
                {reportDownload}
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>{displayMessage}</h3>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default StakeReport;
