import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Auth } from 'aws-amplify';
import * as axios from 'axios';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ReactTable from '../components/Tables/ReactTableHistory';

export const History = () => {
  const [miData, setMiData] = useState([]);
  const [miL15Data, setMiL15Data] = useState([]);
  const [miPatentData, setMiPatentData] = useState([]);
  const [miTrixieData, setMiTrixieData] = useState([]);
  const [miDoubleData, setMiDoubleData] = useState([]);
  const [miSingleData, setMiSingleData] = useState([]);

  const getMiData = async () => {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();

    const { data: miRes } = await axios.get(
      `https://api.valueservices.uk/horsebettingmx/betsMi`,
      { headers: { Authorization: token } }
    );

    return miRes.sort((a, b) => {
      const dateA = new Date(a.historyDate);
      const dateB = new Date(b.historyDate);
      return dateA - dateB;
    });
  };

  const getMiL15Data = async () => {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();

    const { data: miRes } = await axios.get(
      `https://api.valueservices.uk/horsebettingmx/betsMiL15`,
      { headers: { Authorization: token } }
    );

    return miRes.sort((a, b) => {
      const dateA = new Date(a.historyL15Date);
      const dateB = new Date(b.historyL15Date);
      return dateA - dateB;
    });
  };

  const getMiPatentData = async () => {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();

    const { data: miRes } = await axios.get(
      `https://api.valueservices.uk/horsebettingmx/betsMiPatent`,
      { headers: { Authorization: token } }
    );

    return miRes.sort((a, b) => {
      const dateA = new Date(a.historyPatentDate);
      const dateB = new Date(b.historyPatentDate);
      return dateA - dateB;
    });
  };

  const getMiTrixieData = async () => {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();

    const { data: miRes } = await axios.get(
      `https://api.valueservices.uk/horsebettingmx/betsMiTrixie`,
      { headers: { Authorization: token } }
    );

    return miRes.sort((a, b) => {
      const dateA = new Date(a.historyTrixieDate);
      const dateB = new Date(b.historyTrixieDate);
      return dateA - dateB;
    });
  };

  const getMiDoubleData = async () => {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();

    const { data: miRes } = await axios.get(
      `https://api.valueservices.uk/horsebettingmx/betsMiDouble`,
      { headers: { Authorization: token } }
    );

    return miRes.sort((a, b) => {
      const dateA = new Date(a.historyDoubleDate);
      const dateB = new Date(b.historyDoubleDate);
      return dateA - dateB;
    });
  };

  const getMiSingleData = async () => {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();

    const { data: miRes } = await axios.get(
      `https://api.valueservices.uk/horsebettingmx/betsMiSingle`,
      { headers: { Authorization: token } }
    );

    return miRes.sort((a, b) => {
      const dateA = new Date(a.historySingleDate);
      const dateB = new Date(b.historySingleDate);
      return dateA - dateB;
    });
  };

  useEffect(() => {
    async function fetchData() {
      let mi = await getMiData();
      mi && setMiData(mi);
      let miL15 = await getMiL15Data();
      miL15 && setMiL15Data(miL15);
      let miPatent = await getMiPatentData();
      miPatent && setMiPatentData(miPatent);
      let miTrixie = await getMiTrixieData();
      miTrixie && setMiTrixieData(miTrixie);
      let miDouble = await getMiDoubleData();
      miDouble && setMiDoubleData(miDouble);
      let miSingle = await getMiSingleData();
      miSingle && setMiSingleData(miSingle);
    }
    fetchData();
  }, []);

  const options = {
    chart: {
      height: 350,
      type: 'line',
      id: 'areachart-2',
      foreColor: document.body.classList.toString().includes('white-content')
        ? 'black'
        : 'white',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    colors: ['#FF0000'],
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    xaxis: {
      type: 'datetime',
    },
  };

  const cumulativeProfitOptions = {
    ...options,
    title: { text: 'Cumulative Profit', align: 'left' },
    yaxis: {
      labels: {
        formatter: (value) =>
          new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
          }).format(value),
      },
    },
  };
  const cumulativeStakeOptions = {
    ...options,
    title: { text: 'Cumulative Stake', align: 'left' },
    yaxis: {
      labels: {
        formatter: (value) =>
          new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
          }).format(value),
      },
    },
  };
  const cumulativeProfitPercentageOptions = {
    ...options,
    title: { text: 'Cumulative Profit Percentage', align: 'left' },
    yaxis: {
      labels: {
        formatter: (value) => `${value.toFixed(0)}%`,
      },
    },
  };

  return (
    <>
      <div className="content">
        <Container fluid style={{ marginTop: 20 }}>
          {/* Row Containing RaceCard and Bet Summaray components */}
          <Row>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Profit £',
                        data: miData
                          ? miData.map((d) => d.cumulativeProfit.toFixed(2))
                          : [],
                      },
                    ]}
                    options={{
                      ...cumulativeProfitOptions,
                      labels: miData.map((d) => d.historyDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Stake £',
                        data: miData
                          ? miData.map((d) => d.cumulativeStake.toFixed(2))
                          : [],
                      },
                    ]}
                    options={{
                      ...cumulativeStakeOptions,
                      labels: miData.map((d) => d.historyDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Profit %',
                        data: miData
                          ? miData.map((d) =>
                              d.cumulativeProfitPercentage.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...cumulativeProfitPercentageOptions,
                      labels: miData.map((d) => d.historyDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Profit £',
                        data: miL15Data
                          ? miL15Data.map((d) => d.cumulativeProfit.toFixed(2))
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeProfitOptions,
                        title: {
                          text: 'Lucky 15 Cumulative Profit',
                          align: 'left',
                        },
                      },
                      labels: miL15Data.map((d) => d.historyL15Date),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Stake £',
                        data: miL15Data
                          ? miL15Data.map((d) => d.cumulativeStake.toFixed(2))
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeStakeOptions,
                        title: {
                          text: 'Lucky 15 Cumulative Stake',
                          align: 'left',
                        },
                      },
                      labels: miL15Data.map((d) => d.historyL15Date),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Profit %',
                        data: miL15Data
                          ? miL15Data.map((d) =>
                              d.cumulativeProfitPercentage.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeProfitPercentageOptions,
                        title: {
                          text: 'Lucky 15 Profit Percentage',
                          align: 'left',
                        },
                      },
                      labels: miL15Data.map((d) => d.historyL15Date),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Profit £',
                        data: miPatentData
                          ? miPatentData.map((d) =>
                              d.cumulativeProfit.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeProfitOptions,
                        title: {
                          text: 'Patent Cumulative Profit',
                          align: 'left',
                        },
                      },
                      labels: miPatentData.map((d) => d.historyPatentDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Stake £',
                        data: miPatentData
                          ? miPatentData.map((d) =>
                              d.cumulativeStake.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeStakeOptions,
                        title: {
                          text: 'Patent Cumulative Stake',
                          align: 'left',
                        },
                      },
                      labels: miPatentData.map((d) => d.historyPatentDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Profit %',
                        data: miPatentData
                          ? miPatentData.map((d) =>
                              d.cumulativeProfitPercentage.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeProfitPercentageOptions,
                        title: {
                          text: 'Patent Profit Percentage',
                          align: 'left',
                        },
                      },
                      labels: miPatentData.map((d) => d.historyPatentDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Profit £',
                        data: miTrixieData
                          ? miTrixieData.map((d) =>
                              d.cumulativeProfit.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeProfitOptions,
                        title: {
                          text: 'Trixie Cumulative Profit',
                          align: 'left',
                        },
                      },
                      labels: miTrixieData.map((d) => d.historyTrixieDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Stake £',
                        data: miTrixieData
                          ? miTrixieData.map((d) =>
                              d.cumulativeStake.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeStakeOptions,
                        title: {
                          text: 'Trixie Cumulative Stake',
                          align: 'left',
                        },
                      },
                      labels: miTrixieData.map((d) => d.historyTrixieDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Profit %',
                        data: miTrixieData
                          ? miTrixieData.map((d) =>
                              d.cumulativeProfitPercentage.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeProfitPercentageOptions,
                        title: {
                          text: 'Trixie Profit Percentage',
                          align: 'left',
                        },
                      },
                      labels: miTrixieData.map((d) => d.historyTrixieDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Profit £',
                        data: miDoubleData
                          ? miDoubleData.map((d) =>
                              d.cumulativeProfit.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeProfitOptions,
                        title: {
                          text: 'Double Cumulative Profit',
                          align: 'left',
                        },
                      },
                      labels: miDoubleData.map((d) => d.historyDoubleDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Stake £',
                        data: miDoubleData
                          ? miDoubleData.map((d) =>
                              d.cumulativeStake.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeStakeOptions,
                        title: {
                          text: 'Double Cumulative Stake',
                          align: 'left',
                        },
                      },
                      labels: miDoubleData.map((d) => d.historyDoubleDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Profit %',
                        data: miDoubleData
                          ? miDoubleData.map((d) =>
                              d.cumulativeProfitPercentage.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeProfitPercentageOptions,
                        title: {
                          text: 'Double Profit Percentage',
                          align: 'left',
                        },
                      },
                      labels: miDoubleData.map((d) => d.historyDoubleDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Profit £',
                        data: miSingleData
                          ? miSingleData.map((d) =>
                              d.cumulativeProfit.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeProfitOptions,
                        title: {
                          text: 'Single Cumulative Profit',
                          align: 'left',
                        },
                      },
                      labels: miSingleData.map((d) => d.historySingleDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Stake £',
                        data: miSingleData
                          ? miSingleData.map((d) =>
                              d.cumulativeStake.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeStakeOptions,
                        title: {
                          text: 'Single Cumulative Stake',
                          align: 'left',
                        },
                      },
                      labels: miSingleData.map((d) => d.historySingleDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Chart
                    series={[
                      {
                        name: 'Profit %',
                        data: miSingleData
                          ? miSingleData.map((d) =>
                              d.cumulativeProfitPercentage.toFixed(2)
                            )
                          : [],
                      },
                    ]}
                    options={{
                      ...{
                        ...cumulativeProfitPercentageOptions,
                        title: {
                          text: 'Single Profit Percentage',
                          align: 'left',
                        },
                      },
                      labels: miSingleData.map((d) => d.historySingleDate),
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <ReactTable
                    data={miData}
                    defaultPageSize={20}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    columns={[
                      { Header: 'Date', accessor: 'historyDate' },
                      { Header: 'Volume', accessor: 'volume' },
                      {
                        Header: 'Stake',
                        accessor: 'stake',
                        Cell: (props) =>
                          new Intl.NumberFormat('en-GB', {
                            style: 'currency',
                            currency: 'GBP',
                          }).format(props.value),
                      },
                      {
                        Header: 'Profit',
                        accessor: 'profit',
                        Cell: (props) =>
                          new Intl.NumberFormat('en-GB', {
                            style: 'currency',
                            currency: 'GBP',
                          }).format(props.value),
                      },
                      {
                        Header: 'Returns',
                        accessor: 'payout',
                        Cell: (props) =>
                          new Intl.NumberFormat('en-GB', {
                            style: 'currency',
                            currency: 'GBP',
                          }).format(props.value),
                      },
                      {
                        Header: 'Profit Percentage',
                        accessor: 'profitPercentage',
                        Cell: (props) => props.value.toFixed(2) + '%',
                      },
                      {
                        Header: 'Cumulative Profit Percentage',
                        accessor: 'cumulativeProfitPercentage',
                        Cell: (props) => props.value.toFixed(2) + '%',
                      },
                      {
                        Header: 'Cumulative Profit',
                        accessor: 'cumulativeProfit',
                        Cell: (props) =>
                          new Intl.NumberFormat('en-GB', {
                            style: 'currency',
                            currency: 'GBP',
                          }).format(props.value),
                      },
                    ]}
                  ></ReactTable>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
